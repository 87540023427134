.button {
	border-radius: 50px;
	padding: 9px 20px 9px 20px;
	border: none;
	background-color: #999999;
	color: white;
	margin: 20px auto;
	display: block;
	cursor: pointer;
	pointer-events: auto;
}

.button:hover {
	background-color: #888888;
}

.button.primary {
	background-color: #009fff;
}

.button.primary:hover {
	background-color: #0792e6;
}

.button.disabled {
	background-color: #bbbbbb;
	color: #eeeeee;
	cursor: default;
}

.button.disabled:hover {
	background-color: #bbbbbb;
	color: #eeeeee;
}

:global(.wallet-adapter-button).walletButton {
	margin: 0 auto;
	border-radius: 50px;
	text-transform: uppercase;
	background-color: #009fff;
}
.walletButton::before {
	content: "1.\00a0";
}
:global(.wallet-adapter-button).walletButton:hover {
	background-color: #0792e6;
}

.message {
	background-color: white;
	border-radius: 5px;
	margin: 15px 0px;
	padding: 5px 10px;
}
.message::before {
	content: '🗨️ ';
}

h1.title {
	font-family: 'retro_computer_personal_use';
	text-align: center;
}

section.mintSection {
	padding: 1em 0;
}
